@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap');

* {
  font-family: 'Kanit', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&display=swap');

body {
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.button {
  text-decoration: none;
}

.block {
  text-decoration: none;
  color: black;
}

.bg-white {
  z-index: 1;
}

.navbar-light {
  background-color: #ffff;
  box-shadow: 0 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  height: 100vh;
}

.auth-inner {
  width: 450px;
  margin: auto !important;
  background: rgba(243, 237, 225, 0.8);
  box-shadow: 0 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.wrapper {
  width: 100vw;
  display: flex;
  background-image: url("./imgs/harley-davidson-zGzXsJUBQfs-unsplash.jpg");
  background-size: cover;
  background-position: center;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.errmsg {
  transform-origin: left;
  text-align: center;
  color: red;
  padding: 5px 10px;
  background-color: rgba(255, 38, 0, 0.5);
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.custom-control-label {
  font-weight: 400;
}

.Logo {
  width: 70px;
}

.logo-name {
  font-family: 'Bungee Inline', cursive;
  font-size: 1.5rem;
}

.navbar-brand {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.bike-image {
  max-width: 550px;
}

.title {
  font-size: 25px !important;
  text-align: center;
}

.details-card {
  width: 100%;
  margin-left: 30px;
  max-width: 600px;
  background-color: #ffff;
  box-shadow: 0 14px 80px rgba(34, 35, 58, 0.2);
  padding: 20px;
  border-radius: 15px;
  transition: all 0.3s;
}

@media screen and (max-width: 768px) {
  .bike-image {
    max-width: 300px;
  }

  .details-card {
    width: 100%;
    margin-left: 0;
    max-width: 300px;
    padding: 10px;
  }

  .nav-link {
    display: none;
  }

  .auth-wrapper {
    height: 100vh;
  }

  .auth-inner {
    width: 80%;
    margin: 2rem auto;
  }

  .wrapper {
    background-image: url("./imgs/conor-luddy-R2PpxA2j0pQ-unsplash.jpg");
    background-size: cover;
  }
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
  /* mobile-devices */
  .main-container {
    flex-direction: column;
  }

  .full-image {
    width: 80%;
  }

  .content {
    width: 100%;
    padding-right: 50px;
  }

  .font-bold {
    font-weight: bolder;
    font-size: 30px;
  }

  .button {
    height: 55px;
    width: 90%;
    font-size: 17px;
  }
}
